<template>
  <v-container id="rfp-page" fluid tag="section">
    <v-row class="d-flex flex-row align-items-center justify-content-center" no-gutters>
      <v-col cols="12">
        <base-material-card color="primary" class="px-5 py-3">
          <template v-slot:heading>
            <div class="display-2 font-weight-light">Cities</div>
            <div class="subtitle-1 font-weight-light">A list of All Cities</div>
          </template>
          <v-card-text>
            <v-row class="d-flex flex-row align-items-center justify-content-center justify-content-md-center"
              no-gutters>
              <v-row class="mx-0 mb-0" align="center" justify="center" justify-md="start">
                <v-col cols="12" md="8" lg="5">
                  <v-text-field v-model="search" append-icon="mdi-magnify" label="Search by city name" single-line
                    outlined hide-details @keyup.native="searchFn"></v-text-field>
                </v-col>
                <v-col cols="12" md="2" lg="1">
                  <v-btn color="primary" @click="clearFilterFn" :variant="search ? 'ash-gray' : 'primary'"
                    :disabled="!search">
                    Reset
                  </v-btn>
                </v-col>
                <v-col cols="12" md="2" lg="6" class="d-flex justify-end">
                  <v-btn @click="startNumbeo()" style="color: black; font-weight: bold;" elevation="4"
                    :disabled="numbeo_alert">
                    {{ !numbeo_alert ? 'Sync City Data With Numbeo' : 'Syncing City Data With Numbeo' }}
                  </v-btn>
                </v-col>
              </v-row>
              <v-col cols="12" class="px-0">
                <v-data-table :headers="headers" :items="items" :loading="isLoading" :items-per-page="15"
                  :options.sync="pagination" :footer-props="footerProps" :server-items-length="totalOutputs"
                  @update:items-per-page="getItemPerPage">
                  <template v-slot:item.status="{ item }">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn icon color="grey lighten-1" v-on="on" @click="routeFn(item)">
                          <font-awesome-icon :icon="['fas', 'chevron-right']" />
                        </v-btn>
                      </template>
                      <span class="d-block">{{ getRouteName(item) }}</span>
                    </v-tooltip>
                  </template>
                  <template v-slot:item.delete="{ item }">
                    <DeleteConfirmation :key="item._id" :item="payloadGen(item)" @setDelete="deleteFn" />
                    <!-- itemtype="City" -->
                  </template>
                  <!-- <template v-slot:[`item.directContact`]="{ item }">
                    <v-switch
                      class="text-center"
                      v-model="item.enableDirectContact"
                      :disabled="item.user == null"
                       v-bind="attrs"
                       v-on="on"
                      @change="toggleDirectContact(item)"
                    ></v-switch>
                    </template> -->
                  <!-- <template v-slot:[`item.callForTenders`]="{ item }">
                    <v-switch
                      class="text-center"
                      v-model="item.enableOpenTender"
                      :disabled="item.user == null"
                      @change="toggleOpenTender(item)"
                    ></v-switch>
                  </template> -->
                  <template v-slot:[`item.DCCTR`]="{ item }">
                    <span class="text-capitalize">{{
                      item.dc_ctr ? item.dc_ctr : "N/A"
                      }}</span>
                  </template>
                  <template v-slot:item.city="{ item }">
                    <span class="text-capitalize">{{ item.city }}</span>
                  </template>
                  <template v-slot:item.country="{ item }">
                    <span class="text-capitalize">{{ item.country }}</span>
                  </template>
                  <template v-slot:item.formattedAddress="{ item }">
                    <span class="text-capitalize">{{ item.city }}, {{ item.country }}</span>
                  </template>
                  <!-- <template v-slot:item.isAdvance="{ item }">
                    <v-btn icon color="grey lighten-1">
                      <font-awesome-icon
                        :icon="['fas', item.isAdvance ? 'check-square' : 'ban']"
                        :color="item.isShow ? 'green' : 'grey'"
                      />
                    </v-btn>
                  </template> -->
                  <template v-slot:item.isShow="{ item }">
                    <v-btn icon @click="rankFn(item)">
                      <font-awesome-icon :icon="['fas', item.isShow ? 'check-square' : 'ban']"
                        :color="item.isShow ? 'green' : 'grey'" />
                    </v-btn>
                  </template>
                  <template v-slot:item.isWTTCCertificate="{ item }">
                    <v-dialog v-model="item.isModal" :key="item._id" persistent max-width="400">
                      <template v-slot:activator="{ on }">
                        <v-btn v-on="on" icon>
                          <font-awesome-icon :icon="['fas', 'file-medical']"
                            :color="item.isWTTCCertificate ? 'green' : 'grey'" />
                        </v-btn>
                      </template>
                      <v-card>
                        <v-card-title v-if="!item.isWTTCCertificate">
                          <h3 class="title text-start text-break">
                            Are you sure this city '{{ item.city }}' has The
                            WTTC certificate?
                          </h3>
                        </v-card-title>
                        <v-card-title v-else>
                          <h3 class="title text-start text-break">
                            Are you sure you want to revoke the '{{
                              item.city
                            }}''s WTTC certificate?
                          </h3>
                        </v-card-title>
                        <v-card-text v-if="!item.isWTTCCertificate">
                          <p class="subtitle-2 text-left">
                            You will be updating the city as WTTC certified. Are
                            you sure?
                          </p>
                        </v-card-text>
                        <v-card-text v-else>
                          <p class="subtitle-2 text-left">
                            You will be updating the city as not WTTC certified.
                            Are you sure?
                          </p>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="black" text @click="item.isModal = false">No, Cancel
                          </v-btn>
                          <v-btn v-if="!item.isWTTCCertificate" color="green" text @click="healthBadgeFn(item)">Yes,
                            Update
                          </v-btn>
                          <v-btn v-else color="red" text @click="healthBadgeFn(item)">Yes, Revoke
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </template>
                  <template v-slot:item.isGoogleId="{ item }">
                    <span class="text-capitalize success--text" v-if="item.isGoogleId">Verified</span>
                    <span class="text-capitalize error--text" v-else>Unverified</span>
                  </template>
                  <template v-slot:item.isGoogleId2="{ item }">
                    <GoogleIDModal @initFN="initFn" :key="item._id" :item="item" />
                  </template>
                  <!-- <template v-slot:item.image="{ item }">
                    <ImageAdd @initFN="initFn" :key="item._id" :item="item" />
                  </template>-->
                  <template v-slot:item.tempUpdate="{ item }">
                    <TempAndRainFall @initFN="initFn" :key="item._id" :item="item" />
                  </template>

                  <template v-slot:item.imageUpload="{ item }">
                    <ImageUpload @initFN="initFn" :key="item._id" :item="item" />
                  </template>
                  <template v-slot:item.topicNdSubtopic="{ item }">
                    <TopicNdSubtopic @initFN="initFn" :key="item._id" :item="item" />
                  </template>
                  <template v-slot:item.sustainabilityCommitment="{ item }">
                    <GdsScore @initFN="initFn" :key="item._id" :item="item" />
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Config from "../../../services/config";

import TempAndRainFall from "./Models/TempAndRainFall";
import GoogleIDModal from "@/dashboard/Common/GoogleIDModal";
// import ImageAdd from "./Models/ImageAdd";
import cityApicalls from "../../../services/city.service";
import adminApicalls from "../../../services/admin.sevice";
import directContactApicalls from "../../../services/directContact.service";
import DeleteConfirmation from "@/dashboard/Common/DeleteConfirmation";

import ImageUpload from "./Models/ImageUpload";
import TopicNdSubtopic from "./Models/TopicNdSubtopic";
import GdsScore from "./Models/GdsScore.vue";

export default {
  name: "City",
  components: {
    GoogleIDModal,
    // ImageAdd,
    DeleteConfirmation,
    TempAndRainFall,
    ImageUpload,
    TopicNdSubtopic,
    GdsScore,
  },
  data() {
    return {
      search: null,
      isLoading: false,
      totalOutputs: 0,
      footerProps: { "items-per-page-options": [5, 15, 30] },
      pagination: {
        rowsPerPage: 15,
        page: 1,
      },
      numbeo_alert: false,
      // headers: [
      //   {
      //     sortable: true,
      //     text: "City",
      //     value: "city",
      //     class: "primary--text title",
      //   },
      //   {
      //     sortable: true,
      //     text: "Country",
      //     value: "country",
      //     class: "primary--text title",
      //   },

      //   {
      //     sortable: true,
      //     text: "Address",
      //     value: "formattedAddress",
      //     class: "primary--text title",
      //   },
      //   {
      //     sortable: true,
      //     text: "Advance",
      //     value: "isAdvance",
      //     class: "primary--text title",
      //   },
      //   {
      //     sortable: true,
      //     text: "Verified",
      //     value: "isGoogleId",
      //     class: "primary--text title",
      //   },
      //   {
      //     sortable: false,
      //     text: "Updated",
      //     value: "isGoogleId2",
      //     class: "primary--text title",
      //   },
      //   // {
      //   //   sortable: false,
      //   //   text: "Add Image",
      //   //   value: "image",
      //   //   class: "primary--text title",
      //   // },
      //   {
      //     sortable: false,
      //     text: "Delete",
      //     value: "delete",
      //     class: "primary--text title",
      //   },
      //   {
      //     sortable: false,
      //     text: "WTTC Certificate",
      //     value: "isWTTCCertificate",
      //     class: "primary--text title",
      //   },
      //   {
      //     sortable: false,
      //     text: "Temp & RainFall",
      //     value: "tempUpdate",
      //     class: "primary--text title",
      //   },
      //   {
      //     sortable: false,
      //     text: "Topics",
      //     value: "topicNdSubtopic",
      //     class: "primary--text title",
      //   },

      //   {
      //     sortable: false,
      //     text: "Image Upload",
      //     value: "imageUpload",
      //     class: "primary--text title",
      //   },
      //   {
      //     sortable: false,
      //     text: "City Show",
      //     value: "isShow",
      //     class: "primary--text title",
      //   },
      //   {
      //     sortable: false,
      //     text: "More",
      //     value: "status",
      //     class: "primary--text title",
      //   },
      // ],
      items: [],
    };
  },
  computed: {
    headers() {
      let adminType = JSON.parse(localStorage.getItem("admin")).admin.adminType;
      if (adminType == "master" || adminType == "superAdmin") {
        return [
          {
            sortable: false,
            text: "City",
            value: "city",
            class: "primary--text title",
          },
          {
            sortable: false,
            text: "Country",
            value: "country",
            class: "primary--text title",
          },

          {
            sortable: false,
            text: "Address",
            value: "formattedAddress",
            class: "primary--text title",
          },
          // {
          //   sortable: false,
          //   text: "Advance",
          //   value: "isAdvance",
          //   class: "primary--text title",
          // },
          {
            sortable: false,
            text: "Verified",
            value: "isGoogleId",
            class: "primary--text title",
          },
          {
            sortable: false,
            text: "Updated",
            value: "isGoogleId2",
            class: "primary--text title",
          },
          // {
          //   sortable: false,
          //   text: "Add Image",
          //   value: "image",
          //   class: "primary--text title",
          // },
          {
            sortable: false,
            text: "Delete",
            value: "delete",
            class: "primary--text title",
          },
          {
            sortable: false,
            text: "WTTC Certificate",
            value: "isWTTCCertificate",
            class: "primary--text title",
          },
          // {
          //   sortable: false,
          //   text: "Dir. Contact",
          //   value: "directContact",
          //   class: "primary--text title",
          // },
          // {
          //   sortable: false,
          //   text: "Call for Tenders",
          //   value: "callForTenders",
          //   class: "primary--text title",
          // },
          {
            sortable: false,
            text: "D.C CTR",
            value: "DCCTR",
            class: "primary--text title",
          },
          {
            sortable: false,
            text: "Temp & RainFall",
            value: "tempUpdate",
            class: "primary--text title",
          },
          {
            sortable: false,
            text: "Topics",
            value: "topicNdSubtopic",
            class: "primary--text title",
          },
          {
            sortable: false,
            text: "Sust. Initiatives",
            value: "sustainabilityCommitment",
            class: "primary--text title",
          },
          {
            sortable: false,
            text: "Image Upload",
            value: "imageUpload",
            class: "primary--text title",
          },
          {
            sortable: false,
            text: "City Show",
            value: "isShow",
            class: "primary--text title",
          },
          {
            sortable: false,
            text: "More",
            value: "status",
            class: "primary--text title",
          },
        ];
      } else {
        return [
          {
            sortable: false,
            text: "City",
            value: "city",
            class: "primary--text title",
          },
          {
            sortable: false,
            text: "Country",
            value: "country",
            class: "primary--text title",
          },

          {
            sortable: false,
            text: "Address",
            value: "formattedAddress",
            class: "primary--text title",
          },
          {
            sortable: false,
            text: "Advance",
            value: "isAdvance",
            class: "primary--text title",
          },
          {
            sortable: false,
            text: "Verified",
            value: "isGoogleId",
            class: "primary--text title",
          },
          {
            sortable: false,
            text: "Updated",
            value: "isGoogleId2",
            class: "primary--text title",
          },
          {
            sortable: false,
            text: "WTTC Certificate",
            value: "isWTTCCertificate",
            class: "primary--text title",
          },
          {
            sortable: false,
            text: "Dir. Contact",
            value: "directContact",
            class: "primary--text title",
          },
          {
            sortable: false,
            text: "Call for Tenders",
            value: "callForTenders",
            class: "primary--text title",
          },
          {
            sortable: false,
            text: "Temp & RainFall",
            value: "tempUpdate",
            class: "primary--text title",
          },
          {
            sortable: false,
            text: "Topics",
            value: "topicNdSubtopic",
            class: "primary--text title",
          },
          {
            sortable: false,
            text: "Sust. Initiatives",
            value: "sustainabilityCommitment",
            class: "primary--text title",
          },
          {
            sortable: false,
            text: "Image Upload",
            value: "imageUpload",
            class: "primary--text title",
          },
          {
            sortable: false,
            text: "City Show",
            value: "isShow",
            class: "primary--text title",
          },
          {
            sortable: false,
            text: "More",
            value: "status",
            class: "primary--text title",
          },
        ];
      }
    },
  },
  mounted() {
    // this.initFn();
  },
  watch: {
    pagination() {
      this.initFn();
    },
  },
  methods: {
    async startNumbeo() {
      if (!this.numbeo_alert) {
        this.numbeo_alert = true;
        await adminApicalls.startNumbeo();
        this.numbeo_alert = false;
      }
    },
    initFn() {
      this.isLoading = true;
      const { page, rowsPerPage } = this.pagination;
      cityApicalls
        .getAllCity({
          limit: rowsPerPage,
          page: page,
          name: this.search,
        })
        .then((res) => {
          this.totalOutputs = res.data.data.total;
          this.items = res.data.data.results;
          console.log(this.items);
          this.isLoading = false;
        });
      adminApicalls.getNumbeoStatus().then((res) => {
        this.numbeo_alert = res.data.hasOngoing;
      });
    },
    getItemPerPage(val) {
      this.pagination.page = 1;
      this.pagination.rowsPerPage = val;
    },
    rankFn(val) {
      this.isLoading = true;
      let payload = {
        _id: val._id,
        // isShow: val.isShow
      };
      cityApicalls.SetShow(payload).then((res) => {
        // console.log(res.data);
        let result = res.data;
        console.log("resr", result);
        console.log("val.isShow", val.isShow);
        if (result.success) {
          let payload2 = {
            snackBarText: `The city '${val.city}' has been set to ${val.isShow ? "hidden" : "shown"
              }.`,
            snackBarColor: "secondary",
          };
          this.$store.dispatch("notify/setSnackbarMessage", payload2);
          this.initFn();
        }
        this.isLoading = false;
      });
      // console.log(payload);
    },
    healthBadgeFn(val) {
      this.isLoading = true;
      let payload = {
        _id: val._id,
        // isWTTCCertificate: val.isWTTCCertificate
      };
      // console.log(payload);
      cityApicalls.SetWTTCCertificate(payload).then((res) => {
        if (res.data.success) {
          let payload2 = {
            snackBarText: `The city '${val.city}''s WTTC Certificate staus has been Updated successfully`,
            snackBarColor: "secondary",
          };
          this.$store.dispatch("notify/setSnackbarMessage", payload2);
          this.initFn();
        }
        this.isLoading = false;
      });
    },
    routeFn(item) {
      const dataToSend = { admin: true };
      const queryString = new URLSearchParams(dataToSend).toString();
      let url = `${Config.userSite}/cities${item.url}?${queryString}`;
      // let url = `${Config.userSite}/cities/${item.googlePlaceId}`;
      var win = window.open(url, "_blank");
      win.focus();
    },
    getRouteName(item) {
      return `${Config.userSite}/cities${item.url}`;
    },
    payloadGen(city) {
      return {
        name: city.city,
        type: "city",
        _id: city._id,
      };
    },
    deleteFn(val) {
      // console.log(val);
      this.isLoading = true;
      let payload = {
        _id: val._id,
      };
      // console.log(payload);

      cityApicalls.setDelete(payload).then((res) => {
        // console.log(res.data);

        if (res.data.success) {
          let payload2 = {
            snackBarText: `The city '${val.name}'  has been deleted successfully`,
            snackBarColor: "secondary",
          };
          this.deleteDialog = false;
          this.$store.dispatch("notify/setSnackbarMessage", payload2);
          this.initFn();
        }
        this.isLoading = false;
      });
    },
    searchFn() {
      if (this.search && this.search.length > 0) {
        this.pagination.page = 1;
        this.initFn();
      } else {
        this.clearFilterFn();
      }
    },
    clearFilterFn() {
      this.search = null;

      this.pagination.page = 1;
      this.initFn();
    },
    toggleDirectContact(val) {
      console.log(val);
      directContactApicalls
        .updateAccess(val._id, val.enableDirectContact)
        .then((res) => {
          console.log(res);
        });
    },
    toggleOpenTender(val) {
      console.log(val);
      directContactApicalls
        .updateOpenTenderAccess(val._id, val.enableOpenTender)
        .then((res) => {
          console.log(res);
        });
    },
  },
};
</script>
