<template>
  <v-dialog v-model="topicSubTopicDialog" :key="item._id" max-width="500" scrollable>
    <template v-slot:activator="{ on }">
      <v-btn v-on="on" icon color="grey lighten-1">
        <v-icon color="blue">mdi-comment-text-multiple</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="px-2 px-sm-6">
        <v-row class="pb-3">
          <v-col cols="8" lg="10" class="d-flex flex-column justify-center align-start py-0">
            <h3 class="title text-start text-break">
              Update topics and subtopics for city:
              <span
                class="text-capitalize font-weight-bold"
              >{{item.city}}</span>
            </h3>
          </v-col>
          <v-col cols="4" lg="2" class="d-flex flex-column justify-center align-end py-0">
            <v-btn icon color="grey lighten-1" @click="topicSubTopicDialog = false">
              <v-icon class="dialog-close-icon">mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-row class="mx-0" align="start" justify="space-between">
          <v-col cols="12" class="py-0">
            <!-- <v-text-field class="my-0" label="Subject" outlined></v-text-field> -->
            <v-autocomplete
              :items="topicArray"
              v-model="mainTopic"
              :allow-overflow="false"
              color="primary"
              item-color="primary"
              item-text="name"
              label="Main topics city specializes in"
              :error-messages="errorMainTopic"
              clear-icon="mdi-close"
              clearable
              outlined
              multiple
              required
              chips
              small-chips
              deletable-chips
            >
              <template v-slot:selection="{ attr, on, item, selected }">
                <v-chip
                  v-bind="attr"
                  :input-value="selected"
                  color="platinum"
                  class="secondary--text"
                  close
                  close-icon="mdi-close"
                  v-on="on"
                >
                  <span v-text="item.name" class="text-truncate" style="max-width: 80%"></span>
                </v-chip>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" class="py-0">
            <!-- <v-text-field class="my-0" label="Sub Topics" outlined></v-text-field> -->
            <v-autocomplete
              :items="subTopicArray"
              :allow-overflow="false"
              label="Subtopics city specializes in"
              v-model="subTopic"
              :error-messages="errorSubTopic"
              clear-icon="mdi-close"
              clearable
              outlined
              multiple
              required
              chips
              small-chips
              deletable-chips
            >
              <template v-slot:selection="{ attr, on, item, selected }">
                <v-chip
                  v-bind="attr"
                  :input-value="selected"
                  color="platinum"
                  class="secondary--text"
                  close
                  close-icon="mdi-close"
                  v-on="on"
                >
                  <span v-text="item" class="text-truncate" style="max-width: 80%"></span>
                </v-chip>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="px-6">
        <v-row
          class="d-flex flex-column-reverse flex-sm-row align-center justify-space-between mx-0"
        >
          <v-col cols="12" sm="4" class="d-flex flex-column align-center align-sm-start px-0">
            <v-btn
              class="px-8 mx-0"
              color="secondary"
              @click="topicSubTopicDialog = false"
              tile
              outlined
              depressed
              bottom
            >Cancel</v-btn>
          </v-col>
          <v-col cols="12" sm="4" class="d-flex flex-column align-center align-sm-end px-0">
            <v-btn
              class="px-8 mx-0"
              @click="submitFn"
              color="secondary"
              tile
              depressed
              bottom
              right
            >Submit</v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import cityApicalls from "../../../../services/city.service";
import TopicArray from "./Topics";

export default {
  name: "Topics",
  props: {
    item: Object,
  },
  data() {
    return {
      topicSubTopicDialog: false,
      topicArray: [],
      subTopic: [],
      mainTopic: [],
      errorSubTopic: "",
      errorMainTopic: "",
      staticErrorMessages: {
        mainTopic: {
          isEmpty: "Please select The Main Topic",
        },
        subTopic: {
          isEmpty: "Please select The Sub Topic",
        },
      },
    };
  },
  computed: {
    subTopicArray() {
      return this.generateSubTopicArray();
    },
  },
  watch: {
    topicSubTopicDialog(val) {
      if (val) {
        this.initFn();
      }
    },
    mainTopic(newTopics, oldTopics) {
      const removedTopic = oldTopics.find(topic => !newTopics.includes(topic))
      if (removedTopic) {
        const removedTopicInfo = TopicArray.find(
          topic => topic.name === removedTopic
        )
        const newSubTopicList = this.subTopic.filter(
          item => !removedTopicInfo.subtopics.includes(item)
        )
        this.subTopic = newSubTopicList
      }
    },
  },
  created() {
    this.topicArray = TopicArray;
  },
  methods: {
    filterSubTopic() {
      let filterTopics = []
      this .subTopic.map((sub) =>{
        this.subTopicArray.map((top) =>{
          if(sub=== top){
            filterTopics.push(sub)
          }
        })
      })
      return filterTopics;
    },
    generateSubTopicArray() {
      let subTopicArray = [];
      if (this.mainTopic) {
        TopicArray.filter((topics) => {
          this.mainTopic.map((val) => {
            if (topics.name === val) {
              subTopicArray = [...subTopicArray, ...topics.subtopics];
            }
          });
        });
      }
      subTopicArray = [...new Set(subTopicArray)];
      return subTopicArray;
    },
    async initFn() {
      let payload = {
        googleId: this.item.googlePlaceId,
      };
      try {
        let { data } = await cityApicalls.GetCityProfileById(payload);
        if (data.message == "success") {
          this.mainTopic = data.content.city.topic;
          this.subTopic = data.content.city.supTopic;
        }
      } catch (error) {
        let payload2 = {
          snackBarText: error,
          snackBarColor: "error",
        };
        this.$store.dispatch("notify/setSnackbarMessage", payload2);
      }
    },
    submitFn() {
      let payload = {
        _id: this.item._id,
        topic: this.mainTopic,
        supTopic: this.filterSubTopic(),
        topicArray: [
          ...this.mainTopic.map((mainTopicVal) => {
            let returnObject = {
              topic: mainTopicVal,
              suptopic: [],
            };
            this.topicArray.map((mainTopicArrayVal) => {
              if (mainTopicArrayVal.name === mainTopicVal) {
                this.subTopic.filter((subval) => {
                  let index = mainTopicArrayVal.subtopics.indexOf(subval);
                  if (index > -1) {
                    returnObject.suptopic.push(
                      mainTopicArrayVal.subtopics[index]
                    );
                  }
                });
              }
            });
            return returnObject;
          }),
        ],
      };
      // console.log(payload);
      cityApicalls.setTopic(payload).then(() => {
        this.topicSubTopicDialog = false;
        let payload2 = {
          snackBarText: "City Topics updated successfully",
          snackBarColor: "secondary",
        };
        this.$store.dispatch("notify/setSnackbarMessage", payload2);
        this.$emit("initFN");
      });
    },
  },
};
</script>
<style scoped>
.img-placeholder {
  width: 100%;
  height: auto;
  border-radius: 5px;
}
.img-cover-placeholder {
  width: 100%;
  height: auto;
  max-height: 300px;
  border-radius: 5px;
}
</style>
<style>
.v-dialog .v-card .v-card__title .v-icon.dialog-close-icon {
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  width: 32px;
  height: 32px;
  font-size: 24px;
}
</style>