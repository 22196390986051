<template>
  <v-dialog
    v-model="tempUpdateDlg"
    :key="item._id"
    persistent
    max-width="500"
    scrollable
  >
    <template v-slot:activator="{ on }">
      <v-btn v-on="on" icon color="grey lighten-1">
        <font-awesome-icon :icon="['fas', 'cloud-sun-rain']" color="orange" />
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="px-2 px-sm-6">
        <v-row class="pb-3">
          <v-col
            cols="8"
            lg="10"
            class="d-flex flex-column justify-center align-start py-0"
          >
            <h3 class="title text-start text-break">
              Update of Average Temprature and RainFall in October for:
              <span class="text-left capitalize">'{{ item.city }}'</span>
            </h3>
          </v-col>
          <v-col
            cols="4"
            lg="2"
            class="d-flex flex-column justify-center align-end py-0"
          >
            <v-btn icon color="grey lighten-1" @click="tempUpdateDlg = false">
              <v-icon class="dialog-close-icon">mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-row
          class="mx-0"
          align="center"
          justify="center"
          justify-md="start"
          no-gutters
        >
          <v-col cols="12" class="px-0 py-0">
            <v-row class="mx-0" no-gutters>
              <span class="text-left secondary--text subtitle-1">
                <b>Average Temprature in October.</b>
              </span>
            </v-row>
          </v-col>
          <v-col
            cols="12"
            class="d-flex flex-column align-start justify-start px-0 mb-6"
          >
            <v-row class="mx-0 w-100" no-gutters>
              <v-text-field
                v-model="avgTmpInOct"
                label="Average Temprature in October"
                placeholder
                hide-details="auto"
                outlined
                full-width
                @keypress="[isNumber()]"
              ></v-text-field>
              <!-- <v-img class="img-placeholder" :src="imgUrl"></v-img> -->
            </v-row>
          </v-col>
          <v-col
            cols="12"
            class="d-flex flex-column align-start justify-start px-0 py-0"
          >
            <v-row class="mx-0" no-gutters>
              <span class="text-left secondary--text subtitle-1">
                <b>Average Rain Fall in October.</b>
              </span>
            </v-row>
          </v-col>
          <v-col
            cols="12"
            class="d-flex flex-column align-start justify-start px-0"
          >
            <v-row class="mx-0 w-100" no-gutters>
              <v-text-field
                v-model="rainfallOct"
                label="Average Rain Fall in October"
                placeholder
                hide-details="auto"
                outlined
                full-width
                @keypress="[isNumber()]"
              ></v-text-field>
              <!-- <v-img class="img-placeholder" :src="imgCoverUrl"></v-img> -->
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="px-6">
        <v-row
          class="
            d-flex
            flex-column-reverse flex-sm-row
            align-center
            justify-space-between
            mx-0
          "
        >
          <v-col
            cols="12"
            sm="4"
            class="d-flex flex-column align-center align-sm-start px-0"
          >
            <v-btn
              class="px-8 mx-0"
              color="secondary"
              @click="tempUpdateDlg = false"
              tile
              outlined
              depressed
              bottom
              >Cancel</v-btn
            >
          </v-col>
          <v-col
            cols="12"
            sm="4"
            class="d-flex flex-column align-center align-sm-end px-0"
          >
            <v-btn
              class="px-8 mx-0"
              @click="tempUpdateFn"
              color="secondary"
              tile
              depressed
              bottom
              right
              >Submit</v-btn
            >
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import cityApicalls from "../../../../services/city.service";
import isNumber from "../../../../mixin/IsNumber";
export default {
  name: "AddImage",
  mixins: [isNumber],
  props: {
    item: Object,
  },
  data() {
    return {
      tempUpdateDlg: false,
      avgTmpInOct: null,
      rainfallOct: null,
    };
  },
  watch: {},
  methods: {
    tempUpdateFn() {
      let payload = {
        _id: this.item._id,
        avgTmpInOct: this.avgTmpInOct,
        rainfallOct: this.rainfallOct,
      };
      // console.log(payload);
      cityApicalls.SetTempAndRainFallInOct(payload).then((res) => {
        // console.log(res.data)
        if (res.data.success) {
          let payload2 = {
            snackBarText: `The city '${this.item.name}''s temprature and rainfall data updated successfully`,
            snackBarColor: "secondary",
          };
          this.tempUpdateDlg = false;
          this.$store.dispatch("notify/setSnackbarMessage", payload2);
          this.$emit("initFN");
        }
      });
    },
  },
};
</script>
<style scoped>
.capitalize {
  text-transform: capitalize;
}
.img-placeholder {
  width: 100%;
  height: auto;
  border-radius: 5px;
}
.img-cover-placeholder {
  width: 100%;
  height: auto;
  max-height: 300px;
  border-radius: 5px;
}
</style>
